export default {
  "": {
    "id": 2023,
    "temp_key": "",
    "site_id": 29932,
    "name": "",
    "items": [
      {
        "id": "42al3v5c8ggs",
        "url": null,
        "use": "page",
        "name": "Úvod",
        "page": "/",
        "blank": null,
        "section": "",
        "document": null,
        "elements": []
      },
      {
        "id": "3tmfhr4akvb8",
        "url": null,
        "use": "page",
        "name": "Služby",
        "page": "/",
        "blank": null,
        "section": "kontakt",
        "document": null,
        "elements": []
      },
      {
        "id": "h6hfw1o5eyph",
        "url": null,
        "use": "page",
        "name": "Kontakt",
        "page": "/",
        "blank": null,
        "section": "kontakt",
        "document": null,
        "elements": []
      },
      {
        "id": "k4jao72h0zqa",
        "url": null,
        "use": "page",
        "name": "Ceník",
        "page": "/",
        "blank": null,
        "section": "cenik",
        "document": null,
        "elements": []
      },
      {
        "id": "cyhwj1g38clp",
        "url": "https://www.solers.legal/blog/",
        "use": "url",
        "name": "Blog",
        "blank": null,
        "section": null,
        "document": null,
        "elements": []
      },
      {
        "id": "t8k8ay4sfbjs",
        "url": "https://www.solers.legal/skoleni/",
        "use": "url",
        "name": "Školení",
        "blank": null,
        "section": null,
        "document": null,
        "elements": []
      },
      {
        "id": "eff493g54xk8",
        "url": "https://solers.legal/",
        "use": "url",
        "name": "Centrála Praha ",
        "blank": null,
        "section": null,
        "document": null,
        "elements": []
      },
      {
        "id": "15eiteuvdnu",
        "url": "https://vkadvokat.cz/",
        "use": "url",
        "name": "Pobočka Hranice"
      }
    ]
  }
}