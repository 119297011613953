import React from 'react'
import { SiteHeader as Component } from '@swp/components'
import items from './ItemSets'

const languages = []

export default function SiteHeader({ set = '', currentLanguage }) {
return (
  <Component className="pos--fix --spacing5 --size-XL --transparent" type={"SiteHeader"} fixed={true} layout={"3"} transparent={true} backgroundColor={"rgba(21,29,37,1)"} social={{"twitter":"","youtube":"","facebook":"","instagram":""}} title={{"color":"rgba(255,255,255,1)","content":"Solers legal Semily","className":"fs--24 swpf--uppercase lh--1"}} button={{"content":"Domluvit schůzku","page":"/","section":"kontakt","className":"btn-box btn-box--pbtn4 btn-box--shape5 ff--2 fs--16 ls--002"}} item={{"color":"rgba(255,255,255,1)","className":"ff--2 fs--16 w--500 ls--002 lh--1"}} items={items?.[set]?.items} languages={languages} currentLanguage={currentLanguage} />
)
}