/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Text, Button, Image, ContactForm } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Solers legal"}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="css-1mg05aj --style4 --left --full pb--0 pl--02" name={"uvod"} fullscreen={true} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/37997/a14ab68e1f4444e4a8ca9fc52310d66c_s=350x_.JPG);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/37997/a14ab68e1f4444e4a8ca9fc52310d66c_s=660x_.JPG);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/37997/a14ab68e1f4444e4a8ca9fc52310d66c_s=860x_.JPG);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/37997/a14ab68e1f4444e4a8ca9fc52310d66c_s=1400x_.JPG);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/37997/a14ab68e1f4444e4a8ca9fc52310d66c_s=2000x_.JPG);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/37997/a14ab68e1f4444e4a8ca9fc52310d66c_s=660x_.JPG);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/37997/a14ab68e1f4444e4a8ca9fc52310d66c_s=1400x_.JPG);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/37997/a14ab68e1f4444e4a8ca9fc52310d66c_s=3000x_.JPG);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" style={{"maxWidth":1360}} columns={"1"} fullscreen={false}>
            
            <ColumnWrapper className="js-anim  --anim7 --anim-s3 pl--0" anim={"7"} animS={"3"} style={{"maxWidth":""}}>
              
              <Title className="title-box fs--86" style={{"maxWidth":""}} content={"<span style=\"color: white;\">Profesionální právní servis<br>pro severovýchod Čech<br></span>"}>
              </Title>

              <Text className="text-box fs--24 w--300" style={{"maxWidth":800}} content={"<br>"}>
              </Text>

              <Button className="btn-box btn-box--pbtn4 btn-box--shape5 ff--2 fs--20 ls--002 lh--1 mt--30" use={"page"} href={"/#kontakt"} content={"Domluvit úvodní konzultaci"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center" name={"rk5swj56s8f"} style={{"marginTop":0,"paddingTop":80}}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--center" content={"Svěřte své právní problémy do péče expertů ze Solers legal s.r.o.<br>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60" name={"30eg56se5hr"} style={{"marginTop":0,"paddingTop":121}}>
          
          <ColumnWrap className="column__flex --center el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"JUDr. Michal Kocián<br>advokát<br>"}>
              </Title>

              <Text className="text-box text-box--center" style={{"maxWidth":399}} content={"Vystudoval Vystudoval Právnickou fakultu Západočeské Univerzity v Plzni\n(titul Mgr.) a Právnickou fakultu Univerzity Karlovy v Praze (titul JUDr.).<br><br>Je zakládajícím společníkem pražské advokátní kanceláře Solers legal s.r.o. a vedoucím jejího litigačního oddělení (sporová agenda).<br><br>Před založením Solers legal s.r.o. a její semilské pobočky působil v přední české advokátní kanceláři s mezinárodním přesahem. Je členem České advokátní komory a rozhodcem při Rozhodčím soudu Hospodářské komory České republiky a Agrární komory České republiky, což přináší unikátní zkušenosti, které jsou v maximální možné míře využívány pro zastupování klientů. Je zapsán v registru poskytovatelů pomoci obětem trestných činů.<br><br>Ve své více než desetileté praxi se věnuje zejména soudním sporům a arbitrážím. Současně se zabývá obchodním a korporátním právem, dále vymáháním pohledávek, stavebnictvím (smlouvy o dílo a s tím související vadná plnění) a závazkovému právu obecně.&nbsp;<br><br><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/37997/e26831b387214e57bcacd6fc2a6b33c2_s=660x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":603}} srcSet={"https://cdn.swbpg.com/t/37997/e26831b387214e57bcacd6fc2a6b33c2_s=350x_.jpg 350w, https://cdn.swbpg.com/t/37997/e26831b387214e57bcacd6fc2a6b33c2_s=660x_.jpg 660w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper >
              
              <Image className="pt--10" alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/37997/1d306d9d53e241c69afe8adf7475d07d_s=660x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":604}} srcSet={"https://cdn.swbpg.com/t/37997/1d306d9d53e241c69afe8adf7475d07d_s=350x_.jpg 350w, https://cdn.swbpg.com/t/37997/1d306d9d53e241c69afe8adf7475d07d_s=660x_.jpg 660w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box" content={"<br><br>JUDr. Andrea Grivalská<br>advokát<br>"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":399}} content={"Vystudovala Právnickou fakultu Univerzity Karlovy.<br><br>Je zakládajícím společníkem pražské advokátní kanceláře Solers legal s.r.o. a vede oddělení, které se zabývá zejména smluvní agendou, manželským majetkovým právem a rodinným právem. <br><br>Před založením Solers legal s.r.o. a její semilské pobočky působila v přední české advokátní kanceláři s mezinárodním přesahem. Je členkou České advokátní komory a je zapsána v registru poskytovatelů pomoci obětem trestných činů.<br><br>Ve své více než desetileté praxi se věnuje převážně sporové agendě, smluvnímu právu, převodům nemovitostí, rodinnému a pracovnímu právu.&nbsp;<br><br><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center" name={"4ps594jccme"} style={{"paddingTop":0,"paddingBottom":139}}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--center">
              
              <Text className="text-box ff--1 fs--30" style={{"maxWidth":956}} content={"<span style=\"color: var(--color-custom-2);\">Při poskytování právních služeb úzce spolupracujeme také s našimi kolegy z pražské centrály advokátní kanceláře Solers legal s.r.o.</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--08 pt--50" name={"sluzby"} style={{"backgroundColor":"rgba(21, 29, 37, 1)"}}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--62" content={"<span style=\"color: var(--color-custom-1--95);\">S čím Vám náš tým zkušených advokátů pomůže?</span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center pb--50 pt--40" name={"sluzby1"} style={{"backgroundColor":"var(--color-supplementary)"}}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s3 --center el--3 pb--10 pt--10" anim={"2"} animS={"3"} style={{"maxWidth":""}} columns={"3"}>
            
            <ColumnWrapper anim={""} animS={"7"} style={{"maxWidth":420}}>
              
              <Title className="title-box" style={{"maxWidth":""}} content={"<span style=\"color: var(--color-dominant);\">Smlouvy</span>"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":""}} content={"Právní pomoc při vyjednávání o podmínkách smluv, jejich tvorbě a revizích.<br>Smlouvy o dílo, zprostředkovatelské smlouvy, nájemní smlouvy, developerské smlouvy, kupní smlouvy, darovací smlouvy, rámcové smlouvy a smlouvy o smlouvách budoucích.<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper anim={""} animS={"4"} style={{"maxWidth":420}}>
              
              <Title className="title-box" style={{"maxWidth":""}} content={"<span style=\"color: var(--color-dominant);\">Nemovitosti</span>"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":""}} content={"Komplexní právní servis při převodu vlastnického práva k nemovitostem (vyjednávání podmínek, sepis příslušných smluv, úschova kupní ceny, příprava a podání návrhu na vklad do katastru nemovitostí, finalizace převodu vlastnického práva).<br>Zastupování před správními úřady.<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper anim={""} animS={"7"} style={{"maxWidth":420}}>
              
              <Title className="title-box" style={{"maxWidth":""}} content={"<span style=\"color: var(--color-dominant);\">Rodinné právo</span>"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":""}} content={"Komplexní právní servis a poradenství ve všech věcech týkajících se péče o nezletilé děti, rozvodu manželství, vypořádání společného jmění manželů,&nbsp; vymáhání dluženého výživného na (ne)zletilé děti, nezaopatřenou matku, (ne)rozvedenou manželku/manžela apod.<br><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s3 --center el--3 pb--10 pt--10" anim={"2"} animS={"3"} style={{"maxWidth":""}} columns={"3"}>
            
            <ColumnWrapper anim={""} animS={"7"} style={{"maxWidth":420}}>
              
              <Title className="title-box" style={{"maxWidth":""}} content={"<span style=\"color: var(--color-dominant);\">Obchodní právo</span>"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":""}} content={"Komplexní právní servis pro obchodní korporace.<br>Zakládání a vznik právnických osob, příprava a právní pomoc s konáním zasedání a rozhodování orgánů obchodní korporace, zajištění realizace změn zápisů v obchodním rejstříku či v evidenci skutečných majitelů, právní audity (due dilligence) obchodních společností, nastavení korporátních procesů, zastoupení klienta při jednání s notářem.<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper anim={""} animS={"4"} style={{"maxWidth":420}}>
              
              <Title className="title-box" style={{"maxWidth":""}} content={"<span style=\"color: var(--color-dominant);\">Občanské právo</span>"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":""}} content={"Právní pomoc a zastoupení klientů ve věcech vedených u civilních soudů, a to na všech instancích včetně Ústavního soudu. Právní servis v případě exekuce a insolvence. Komplexní právní poradenství související s bytovým právem, duševním vlastnictví a vypořádání spoluvlastnictví.<br>Vymáhání pohledávek.<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper anim={""} animS={"7"} style={{"maxWidth":420}}>
              
              <Title className="title-box" style={{"maxWidth":""}} content={"<span style=\"color: var(--color-dominant);\">Trestní právo</span>"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":""}} content={"Obhajoba klientů v řízení před orgány Policie České republiky a v řízení před soudem (včetně řízení o dovolání u Nejvyššího soudu a řízení o ústavní stížnosti před Ústavním soudem). Zastupování poškozených a obětí trestné činnosti. Uplatňování práv poškozených na náhradu škody způsobené trestnou činností. Právní pomoc při podání vysvětlení, účasti na výslechu a při zajištění majetku."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s3 --center el--3 pb--10 pt--10" anim={"2"} animS={"3"} style={{"maxWidth":""}} columns={"3"}>
            
            <ColumnWrapper anim={""} animS={"7"} style={{"maxWidth":420}}>
              
              <Title className="title-box" style={{"maxWidth":""}} content={"<span style=\"color: var(--color-dominant);\">Dědictví</span>"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":""}} content={"Komplexní právní servis v řízení o pozůstalosti. Jednání s notářem, asistence při dohledání obsahu pozůstalosti, zastupování v souvisejících soudních sporech."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper anim={""} animS={"4"} style={{"maxWidth":420}}>
              
              <Title className="title-box" style={{"maxWidth":""}} content={"<span style=\"color: var(--color-dominant);\">Správní právo</span>"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":""}} content={"Komplexní právní servis týkající se stavebního řízení. Zastupování územně samosprávných celků, právo životního prostředí a odpadové hospodářství.<br>Cizinecké a azylové právo.<br>Zastupování klientů v řízení před správními orgány a v souvisejících řízeních před správními soudy, a to včetně řízení u Nejvyššího správního soudu.<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper anim={""} animS={"7"} style={{"maxWidth":420}}>
              
              <Title className="title-box" style={{"maxWidth":""}} content={"<span style=\"color: var(--color-dominant);\">Přestupky</span>"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":""}} content={"Komplexní právní servis a obhajoba v řízení o přestupcích nejen na úseku dopravy."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"cenik"} layout={"l13"}>
          
          <ColumnWrap className="column__flex --left el--2 flex--top" columns={"2"}>
            
            <ColumnWrapper className="--left" style={{"maxWidth":480}}>
              
              <Title className="title-box" content={"Kde nás najdete?"}>
              </Title>

              <Text className="text-box" content={"Sídlíme v secesní vile na nám. Pavla Tigrida 240, 513 01, Semily. Kancelář se nachází ve druhém nadzemním patře. Vstup je z ulice Na Olešce.&nbsp;<br>Pro sjednání osobní schůzky je vhodná předchozí telefonická či e-mailová domluva.<br>"}>
              </Text>

              <Title className="title-box" content={"Cena právních služeb"}>
              </Title>

              <Text className="text-box" content={"Advokátní kancelář poskytuje právní služby za odměnu, která je stanovena dohodou.<br>Advokátní kancelář je plátcem DPH.&nbsp;<br><br>Naše advokátní kancelář má sjednané pojištění profesní odpovědnosti s limitem plnění 200 mil. Kč.&nbsp;<br><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/37997/2cebcd8553ce4709a01bb8f50dd82cc8_s=660x_.jpeg"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":1000}} srcSet={"https://cdn.swbpg.com/t/37997/2cebcd8553ce4709a01bb8f50dd82cc8_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/37997/2cebcd8553ce4709a01bb8f50dd82cc8_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/37997/2cebcd8553ce4709a01bb8f50dd82cc8_s=860x_.jpeg 860w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"kontakt"} style={{"backgroundColor":"rgba(21, 29, 37, 1)"}}>
          
          <ColumnWrap className="column__flex el--2 flex--top" columns={"2"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"<span style=\"color: var(--color-custom-1--95);\">Kontaktujte nás</span>"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":380}} content={"V případě zájmu o právní služby nás neváhejte kontaktovat prostřednictvím formuláře vpravo.<br><br>Odpověď na Vaši zprávu obdržíte nejpozději do druhého pracovního dne.&nbsp;<br><br>Kontaktovat nás můžete také na telefonním čísle +420 602 323 723 JUDr. Michal Kocián<br>+420 702 075 988&nbsp; JUDr. Andrea Grivalská<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <ContactForm className="--shape2 --style1 --right" action={"/contact"} fields={[{"name":"Celé jméno","type":"text","required":true,"placeholder":"Zadejte celé jméno"},{"name":"Váš e-mail","type":"email"},{"name":"Telefonní číslo","type":"text","placeholder":"Telefon +420 "},{"name":"Zpráva","type":"textarea","required":true,"placeholder":"Zadejte text zprávy"},{"name":"Odeslat","type":"submit","align":"center"}]}>
              </ContactForm>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--25 pt--20" name={"paticka"} layout={"l1"}>
          
          <ColumnWrap className="column__flex --left el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--26 swpf--uppercase" content={"Solers legal s.r.o.<br><br>IČO: 17194610<br>DIČ: cz17194610<br>&nbsp;<br>"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":334}} content={"pobočka Semily<br>nám. Pavla Tigrida 240, <br>513 01, Semily<br>Česká Republika<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box fs--26 swpf--uppercase" content={"Kontakt"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":334}} content={"+420 602 323 723<br>michal.kocian@solers.legal<br><br>+420 702 075 988<br>andrea.grivalska@solers.legal<br><br>www.solers.legal<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Text className="text-box text-box--left" style={{"maxWidth":384}} content={"<span style=\"font-weight: bold;\">Informace pro klienty<br></span><br>Subjektem mimosoudního řešení spotřebitelských sporů ze smlouvy o poskytování právních služeb je Česká advokátní komora. Internetová adresa České advokátní komory je www.cak.cz.<br><br>Zpracování osobních údajů <a href=\"https://www.solers.legal/privacy-policy/\">zde</a><br><br><br>"}>
              </Text>

              <Text className="text-box text-box--right" style={{"maxWidth":304}} content={"<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}